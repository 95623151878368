.video {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    background: url('../img/play.svg') no-repeat;
    height: 66px;
    width: 57px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
