.overview {

  .nav-tabs {
    border: none;
    margin-bottom: 15px;

    .nav-link {
      border: none;
      color: $body-color;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0;
      margin-right: 20px;
      cursor: pointer;
      @extend .fs-14;

      &:hover {
        border: none;
      }

      &.active {
        border: none;
        color: $primary;
      }
    }
  }
}

.overview-block {
  margin-bottom: 50px;
}
