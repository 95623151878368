.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    align-items: flex-start;
    justify-content: flex-start;
    // height: calc(100vh - 65px);
    overflow: auto;
    position: relative;
  }

  &.no-header-page {
    @media screen and (orientation: portrait) {
      main {
        transform: rotate(90deg);
        width: 100vh;
        background: transparent;
      }
    }

    &.bg-special {
      @include media-breakpoint-down(sm) {
        align-items: center;
        justify-content: center;
        background-color: $secondary !important;
      }
    }

    .header {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &.landscape-page {
    @media screen and (orientation: portrait) {
      align-items: center;
      justify-content: center;

      main {
        transform: rotate(90deg);
        height: 100vw !important;
        width: 100vh !important;
      }
    }

    @include media-breakpoint-down(sm) {
      main {
        height: 100vh;
        width: 100vw;
      }

      .header,
      .sidebar-menu {
        display: none;
      }

      .content-area {
        flex-direction: initial;
        height: 100%;

        > div:last-child {
          width: 50%;
          height: 100%;
        }
      }

      .title {
        height: 100%;
        width: 50%;
        min-width: initial;

        img {
          height: 100% !important;
        }
      }

      .content {
        max-height: 100%;
        overflow: auto;
      }

      .footer {
        position: absolute;
      }
    }
  }

  &.bg-special,
  &.bg-dark,
  &.bg-secondary,
  &.bg-primary {
    @include media-breakpoint-down(sm) {
      background-color: white !important;
    }
  }

  &.intro-page {
    @include media-breakpoint-down(sm) {
      background-color: $special !important;
    }
  }
}
