main {
  display: flex;
  // flex-direction: column;
  background: white;
  height: 435px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 9;

  @include media-breakpoint-down(sm) {
    width: 100%;
    height: initial;
    box-shadow: none;
    z-index: 0;
  }

  .content-area > div:last-child {
    padding-bottom: 65px;
  }

  &.section-start {
    .content-area {
      @include media-breakpoint-down(sm) {
        padding-bottom: 0;
      }

      > div:last-child {
        @include media-breakpoint-down(sm) {
          padding-bottom: 0;
        }
      }
    }
  }

  &.no-footer {
    @include media-breakpoint-down(sm) {
      min-height: 100vh;
      height: initial;
    }

    .content-area > div:last-child {
      padding-bottom: 0;
    }
  }

  &.bg-dark {
    .content-area {
      background: transparent !important;
    }
  }
}
