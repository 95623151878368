.image-activity {
  position: relative;
  height: 100%;

  @include media-breakpoint-down(sm) {
    height: initial;
    width: 100%;
  }

  .back-btn {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100px;
    justify-content: center;
  }

  .next-btn {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 100px;
    justify-content: center;
  }

  .zoom-btn {
    position: absolute;
    top: 0;
    left: initial;
    right: 0;
    max-width: 100px;
    justify-content: center;
  }

  > img {
    height: 100%;

    @include media-breakpoint-down(sm) {
      height: initial;
      width: 100%;
    }
  }
}

.marker {
  position: absolute;
  left: 0;
  top: 0;
}
