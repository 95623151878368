.section-start {
  .content-area {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      padding-bottom: 65px;
    }

    .content {
      padding-bottom: 0;
    }
  }
}
