.content-area {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    background: white;
    height: initial;
  }

  > div:last-child {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 360px;
    height: 100%;

    @include media-breakpoint-down(sm) {
      height: initial;
      width: 100%;
    }
  }
}

.content {
  max-height: 100%;
  overflow: auto;
  padding: 24px;

    // padding-top: 26px;
  // padding-bottom: 70px;
  // border-bottom: 1px solid #1864AB;

  @include media-breakpoint-down(sm) {
    max-height: initial;
  }
  .btn {
    width: 314px;
  }
  img.w-100 {
    width: calc(100% + 48px) !important;
    max-width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: -24px;
  }

  .arabic-text img.w-100 {
    margin-left: initial;
    margin-right: -24px;
  }
}

.title {
  height: 100%;
  min-width: 350px;

  @include media-breakpoint-down(sm) {
    height: initial;
    min-width: 100%;
  }

  &.bg-primary {
    background: url('../img/shape.svg') no-repeat bottom/100%;
  }

  &.bg-special,
  &.bg-secondary,
  &.bg-dark {
    background: url('../img/shape-white.svg') no-repeat bottom/100%;
  }

  &.title-failed {
    background-image: none;

    img {
      height: initial;
    }
  }

  &.bg-special,
  &.bg-primary,
  &.bg-secondary,
  &.bg-dark {
    padding: 40px 40px 70px;

    @include media-breakpoint-down(sm) {
      padding: 60px 20px 70px;
    }
  }

  img {
    height: 100%;

    @include media-breakpoint-down(sm) {
      height: auto !important;
      width: 100%;
    }
  }

  > *:not(img) {
    max-width: 280px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  > .drag-drop {
    max-width: 380px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
}
