.text-dark-150 {
  color: #1d2125 !important;
}

.arabic-text {
  .select-lang {
    direction: ltr;
    text-align: left !important;
  }
}

.bg-promo {
  // align-items: flex-end !important;
  align-items: center !important;
  justify-content: flex-start !important;
  // background: #e6e7e8 0% 0% no-repeat padding-box;
  // background-image: url("../img/side-wave.svg");
  // background-repeat: repeat-y;
  // background-position: 0 0;
  // position: relative;

  .promo-content {
    width: 1104px;
    font-family: 'Tajawal';
    z-index: 99;

    .video-pos {
      height: 375px;
      width: 670px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      iframe {
        border-radius: 5px;
      }
    }
    .video-pos-line {
      padding-bottom: 70px;
      border-bottom: 1px solid #1864ab;
    }

    .select-lang {
      margin-top: 52px;
      margin-bottom: 91px;
      select {
        font-size: 20.5px;
        line-height: 24px;
        color: #045ab7;
        border: 0 solid;
        background: transparent;
      }
    }

    .content {
      padding-top: 26px;
      padding-bottom: 70px;
      padding-left: 0;
      border-bottom: 1px solid #1864ab;
    }

    .promo-instruction {
      padding-top: 64px;

      .user-attention {
        background: rgba(206, 224, 244, 0.27);
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        padding: 27px 67px 14px 33px;
        color: #1d2125;
        span {
          font-weight: 700;
        }
      }

      .w-991 {
        width: 991px;
      }

      .video-tutorial {
        p {
          font-size: 20.5px;
          font-weight: 700;
          line-height: 25px;
          color: #045ab7;
        }
      }

      .promo-instruction__loginSignup {
        width: 847px;

        &__login-title {
          margin-bottom: 34px;
        }
        &__signup-title {
          margin-bottom: 48px;
        }
      }

      h3 {
        font-size: 28px;
        color: #045ab7;
        font-weight: 700;
      }

      .subtitle3 {
        margin-bottom: 55px;
        margin-top: 55px;
      }

      .quotes {
        margin-bottom: 42px;
        h2 {
          color: #929292;
          font-weight: 700;
          font-size: 58px;
          line-height: 70px;
          margin-bottom: 16px;
        }

        .author {
          color: #045ab7;
          font-size: 24px;
          line-height: 29px;
          font-weight: 700;
          margin-bottom: 12px;
        }
        .author_designation {
          color: #929292;
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
        }
      }

      .partners {
        h2 {
          color: #045ab7;
          font-family: 'Tajawal';
          font-size: 58px;
          font-weight: 700;
        }
        .partners__logo {
          margin-top: 20px;
          margin-bottom: 93px;

          img {
            height: 94px;
          }
        }
      }

      .collage-section {
        margin-top: 94px;
        margin-bottom: 115px;
      }
    }

    .container {
      .loginSignup {
        height: 435px;

        .content-area {
          width: fit-content;
          box-shadow: -2px 0px 13px -5px rgba(0, 0, 0, 0.75);
        }
      }
    }

    .title1 {
      color: #045ab7;
      font-family: 'Tajawal';
      font-size: 58px;
      font-weight: 700;
      // width: 845px;
      margin-bottom: 40px;
    }

    .descr1 {
      width: 991px;
      margin-top: 50px;
      margin-bottom: 36px;
      padding-bottom: 56px;
      border-bottom: 1px solid #1864ab;

      p {
        font-size: 21px;
        margin-bottom: 8px;
      }

      ul {
        list-style: disc;
        margin-left: 21px;
        li {
          font-size: 21px;
        }
      }
    }

    .down-app {
      padding-bottom: 100px;
      h2 {
        font-size: 58px;
        color: #045ab7;
        font-weight: 700;
        margin-bottom: 15px;
      }
      h4 {
        font-size: 28px;
        color: #495057;
        font-weight: 700;
        margin-bottom: 15px;
      }
      p {
        font-size: 18px;
        color: #495057;
        margin-top: 15px;
      }
    }

    .sign-in-osh {
      padding-bottom: 35px;
      border-bottom: 1px solid #1864ab;
      h1 {
        font-weight: 700;
        font-size: 58px;
        line-height: 70px;
        color: #045ab7;
      }
      p {
        font-weight: 700;
        font-size: 20.5px;
        line-height: 25px;
        color: #045ab7;
      }
      > div {
        margin-top: 36px;
      }
    }
  }

  .promo-footer {
    background: #495057 0% 0% no-repeat padding-box;
    padding: 117px 290px 102px 290px;
    width: calc(100% + 40px);
    z-index: 100;
    color: #a7a9ac;
    text-align: center;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    li {
      color: #ffd43b;
      font-family: 'Tajawal';
      font-size: 24px;
      font-weight: 700;
    }

    a {
      color: #ffd43b;
      font-family: 'Tajawal';
      font-size: 24px;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      color: #ffd43b;
      font-family: 'Tajawal';
      font-size: 24px;
      font-weight: 700;
      margin-top: 80px;
    }

    h3 {
      color: #ffd43b;
      font-family: 'Tajawal';
      font-size: 28px;
      font-weight: 700;
    }
  }
}

.signup_btn_mobile {
  background: #3bc9db 0% 0% no-repeat padding-box;
  display: inline-block;
  font: normal normal 900 12px/15px Tajawal;
  letter-spacing: 0px;
  color: #495057;
  text-transform: uppercase;
  min-width: 262px;
  padding: 25px 21px;
  margin-bottom: 36px;
  direction: ltr;
  text-align: left !important;
  svg {
    margin-left: 20px;
  }
}

.login_btn_mobile {
  background: #1864ab 0% 0% no-repeat padding-box;
  display: inline-block;
  font: normal normal 900 12px/15px Tajawal;
  letter-spacing: 0px;
  color: #e9ecef;
  text-transform: uppercase;
  min-width: 262px;
  padding: 25px 21px;
  margin-bottom: 36px;
  direction: ltr;
  text-align: left !important;
  svg {
    margin-left: 20px;
  }
}

.moodle-banner {
  a {
    width: 40%;
  }
  img {
    width: 60%;
  }
}

// Mobile
@include media-breakpoint-down(sm) {
  .moodle-banner {
    a {
      width: 100%;
      height: 65px;
    }
    img {
      width: 0;
    }
  }
  .bg-promo {
    background-image: none;
    .promo-content {
      .title1 {
        font-size: 37px;
        font-weight: 700;
      }
      .sign-in-osh {
        h1 {
          font-size: 37px;
          line-height: 1.2;
        }
      }
      .promo-instruction {
        padding-top: 26px;
        .promo-instruction__loginSignup {
          width: 100%;
          // padding-bottom: 36px;
          border-bottom: 2px solid #a5d8ff;
        }

        .w-991 {
          width: 100%;
        }

        h3 {
          font-size: 22px;
          margin-bottom: 10px !important;
        }

        .subtitle3 {
          margin-top: 20px;
        }

        .quotes {
          h2 {
            font-size: 28px;
            margin-bottom: 52px;
            line-height: 1.2;
          }
        }

        .partners {
          h2 {
            font-size: 37px;
          }

          .partners__logo {
            margin-top: 20px;
            margin-bottom: 50px;
            img {
              margin-bottom: 15px;
            }
          }
        }
      }

      .container {
        display: none;
      }
      .sign-in-osh {
        padding-bottom: 50px;
      }
      .down-app {
        padding-bottom: 50px;
        h2 {
          margin-bottom: 15px;
          font-size: 37px;
        }

        .donwloadBtn {
          img {
            width: 190px;
          }
        }
      }

      .d-m-none {
        display: none;
      }
    }

    .promo-footer {
      text-align: left;

      ul {
        flex-direction: column;
      }

      li {
        font-size: 18px;
      }
      a {
        font-size: 18px;
      }

      p {
        font-size: 18px;
      }

      h3 {
        font-size: 20px;
      }
    }
  }
}

// iPad
@include media-breakpoint-down(md) {
  .bg-promo {
    background-image: none;

    .promo-content {
      .promo-instruction {
        padding-top: 30px;
        .promo-instruction__loginSignup {
          width: 100%;
          border-bottom: 2px solid #a5d8ff;
        }

        .partners {
          .partners__logo {
            margin-top: 30px;
            margin-bottom: 60px;
          }
        }

        .w-991 {
          width: 100%;
        }

        h3 {
          margin-bottom: 15px !important;
        }
      }

      .container {
        display: none;
      }

      .down-app {
        h2 {
          margin-bottom: 15px;
        }
      }

      .d-m-none {
        display: none;
      }
    }
  }
}

// iPad Pro
@include media-breakpoint-down(lg) {
  .bg-promo {
    background-image: none;
    padding: 0 20px;

    .promo-content {
      width: 100%;

      .title1 {
        width: 100%;
      }

      .promo-instruction {
        .promo-instruction__loginSignup {
          width: 100%;
        }

        .w-991 {
          width: 100%;
        }
      }

      .video-pos {
        width: 100%;
      }

      .descr1 {
        width: 100%;
      }
    }

    .promo-footer {
      padding: 60px 40px;
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .bg-promo {
    background-position: -128px 0;

    .promo-content {
      width: 1000px;
    }

    .promo-footer {
      padding: 117px 150px 102px 150px;
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 1440.1px) {
  .bg-promo {
    background-position: -110px 0;
  }
}
