.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 60px;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    padding: 20px;
    width: 20%;
  }
}

.menu-button {
  margin-right: auto;
  border: none;

  &:focus {
    box-shadow: none;
  }
}

.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  background: $light-gray;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
  bottom: 0;
  overflow: auto;
  z-index: 99;
  display: flex;
  flex-direction: column;
  @extend .p-4;

  h4 {
    color: $primary;
    padding-bottom: 16px;
    border-bottom: 1px solid $dark;
    font-size: 15px !important;
    font-weight: 900;
  }

  h3,
  .h3 {
    color: #a5d8ff;
    font-weight: 900;
    font-size: 18px;
  }

  ul {
    li {
      color: $dark;
      font-size: 15px;
      font-weight: 900;
      border-bottom: 1px solid rgba(73, 80, 83, 0.06);
      display: block;
      padding: 8px 0;
      cursor: pointer;

      &.disabled-module {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.app-icon {
  // width: 72px;
  // height: 72px;
  // border-radius: 50%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // background: white;
  // img {
  //   height: 72px;
  // }

  &__logo {
    height: 72px;
  }
  &__text {
    height: 59px;
    margin-right: 20px;
  }

  @include media-breakpoint-down(sm) {
    width: initial;
    height: initial;
    background: transparent;
    display: none;
  }
}

.study-content-header {
  background: url('../img/shape-white.svg') no-repeat bottom/100%;

  h1 {
    padding: 25px 45px 35px 25px;
  }
}

.home-icon {
  background: rgba(255, 255, 255, 0.25);
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 4px;
  top: 8px;
  right: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-icon {
  @extend .home-icon;
  background: rgba(255, 212, 59, 0.75);
  border-radius: 50%;
}

.final-assessment-header {
  background: url('../img/shape-white.svg') no-repeat bottom/100%;
  position: relative;
  padding: 50px 50px 60px 25px;
  @extend .bg-dark;
}
