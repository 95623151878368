@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700;900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

h5 {
  @extend .fs-20;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  border: none;
}

button {
  border: none;
  background: transparent;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none !important;
  }
}

label {
  color: black;

  span {
    color: $secondary;
  }
}

ul {
  // list-style: none;
}

body {
  font-size: 1.125rem !important;
}
