.drag-drop {
  @extend .d-flex;
  @extend .flex-column;
  @extend .p-4;
  @extend .justify-content-between;
  @extend .h-100;

  li {
    background: $light-gray;
    @extend .px-4;
    @extend .py-2;
    @extend .fs-16;
    @extend .fw-600;
    @extend .mb-2;

    position: relative;

    @include media-breakpoint-down(sm) {
      font-size: 13px !important;
    }

    .drag-has-value {
      position: relative;
      height: 20px;
      top: 4px;
      min-width: inherit;
    }

    span {
      &.show-question-positon {
        min-width: 98px !important;
        height: 20px;
        background: red;
        position: relative;
        top: 4px;
        border-radius: 2px;
        @extend .bg-primary;

        @include media-breakpoint-down(sm) {
          min-width: 90px !important;
          height: 14px;
        }
      }

      height: 100%;
      min-width: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 3px;
      text-align: center;
      line-height: 20px;
    }
  }
}

.drag-options {
  @extend .d-flex;
  @extend .flex-column;
  @extend .p-4;

  span {
    @extend .bg-dark;
    @extend .btn;
    @extend .btn-block;
    @extend .text-primary;
    @extend .justify-content-center;
    padding: 12px 40px;
    width: 260px;
    cursor: grab !important;

    @include media-breakpoint-down(sm) {
      height: 45px;
      padding: 2px 0px !important;
    }

    &:focus {
      cursor: grabbing !important;
    }
  }
}

.react-draggable {
  position: absolute;
  z-index: 99;
  max-width: 265px;
}

.tag {
  margin: 3px;
  font-size: 13px;
  border: 1px dashed #cccccc;
  border-radius: 4px;
  padding: 0 8px;
  line-height: 30px;
  color: #666666;
  background: rgba(255, 255, 255, 0.7);
}

.DraggableTags-tag {
  color: $primary !important;
}

.content {
  .drag-options {
    border: 1px dashed #ddd;
    padding: 10px !important;
  }

  .DraggableTags-tag {
    width: 100%;

    span {
      width: 100%;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }
}

.content .DraggableTags {
  min-height: 100px;
}
