.module-list {
  padding: 150px 0;

  @include media-breakpoint-down(sm) {
    padding: 15px 0 80px 0;
  }

  a.btn {
    @include media-breakpoint-down(sm) {
      background: $primary !important;
      margin-top: 30px;
    }
  }
}

.module {
  background: url('../img/shape-special.png') no-repeat bottom/100%;
  border-radius: 15px;
  overflow: hidden;
  transition: 0.3s;
  margin-top: 30px;
  display: block;

  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }

  .final-assesment {
    padding: 15px 20px 30px !important;
  }
  > div:last-child {
    padding: 15px 20px 12px;
  }

  &.disabled-module {
    filter: grayscale(100%);
    pointer-events: none;

    &:hover {
      transform: scale(1);
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}

.assessment-status {
  border-radius: 9px;
  display: flex;
  width: 141px;
  padding: 2px;

  .assessment-status_icon {
    display: flex;
    width: 15px;
    height: 15px;
    background: #000;
    margin-right: 9px;
    border-radius: 50%;
  }

  p {
    font-size: 10px;
    font-weight: 700;
    font-family: Segoe UI;
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &.pending {
    background: #e9ecef;

    .assessment-status_icon {
      background: #f59f00;
    }

    p {
      color: #707070;
    }
  }

  &.done {
    background: #3bc9db;

    .assessment-status_icon {
      background: #1864ab;
    }

    p {
      color: #212529;
    }
  }
}

.covid {
  background: url('../img/shape-white.svg') no-repeat bottom/100% !important;
  background-color: #228be5 !important;
  min-height: 117px;

  > div:last-child {
    padding: 10px 20px 3px !important;
  }

  @include media-breakpoint-down(sm) {
    h6 {
      font-size: 1.125rem !important;
    }
  }
}

.module-image {
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  + div h6 {
    min-height: 55px;
  }
}

.highlighedCard {
  margin-top: -30px;

  @include media-breakpoint-down(sm) {
    > div:first-child {
      margin-top: 15px;
    }
  }

  .btn {
    margin-top: 30px;
  }
}
