.btn {
  height: 65px;
  display: flex;
  align-items: center;

  &.btn-link {
    height: initial;
  }
}

.pointer {
  cursor: pointer;
}

.text-secondary-light {
  color: $secondary-light;
}

.btn-covid {
  background: #228be5 !important;
  color: white !important;
}

.required-star {
  color: #3bc9db !important;
}

.btn-special {
  @extend .btn-secondary;
  background: $special;
  border-color: $special;
  color: white;

  &:hover {
    background: darken($special, 5);
    border-color: darken($special, 5);
    color: white !important;
  }

  &:disabled {
    @extend .btn-secondary;
    background: $special;
    border-color: $special;
    color: white;
  }
}

.btn-secondary {
  &:hover {
    color: inherit;
  }
}

.bg-special {
  background-color: $special !important;
}

.text-special {
  color: $special !important;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.btn-secondary-shadow {
  display: block;
  font-size: 1rem;
  color: #495057;
  font-weight: 900;
  padding: 20px 10px;
  padding-left: calc(10% + 10px);
  background: url('../img/btn-left-shape-2.svg') no-repeat center
    left/calc(10% - 3px);
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: 10px;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    background: $secondary;
    z-index: -1;
  }

  &:hover {
    color: $body-color;
    text-decoration: none;
  }
}

#root {
  @extend .w-100;
}

.disabled-content {
  color: grey !important;
  pointer-events: none;
}

.arabic-text {
  direction: rtl;
  text-align: right !important;

  label {
    padding-right: 20px;
    position: relative;

    input {
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
}
