.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 9;

  @include media-breakpoint-down(sm) {
    position: fixed;
  }
}

.back-btn {
  width: calc(25% - 3px);
}

.next-btn {
  width: calc(75% - 3px);
}

.check-btn {
  width: calc(30% - 3px);

  + .next-btn {
    width: calc(45% - 3px);
  }
}

.failed-footer {
  background: url('../img/empty-shape.svg') no-repeat center
    right/calc(25% - 3px);

  &.reveal-footer {
    background-position: center left;
  }
}
